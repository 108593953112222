$device-width-s: 768px;
$device-width-s-max: $device-width-s - 1px;
$device-width-m: 1024px;
$device-width-m-max: $device-width-m - 1px;
$device-width-l: 1280px;
$device-width-l-max: $device-width-l - 1px;
.header-search {
  height: 24px;
}

.header-top-container--invisible {
  visibility: hidden;
}

.header--overlayed .header-search-icon {
  color: var(--color-white);
}

.header-search-box {
  visibility: visible;
  display: flex;
  position: absolute;
  top: 6px;
  left: 0;
  right: 0;
  margin: 0;
  gap: var(--space-100);

  @media (min-width: $device-width-s) {
    gap: var(--space-800);
    top: 12px;
    margin: 0 28px;
  }

  @media (min-width: $device-width-m) {
    gap: var(--space-1000);
  }

  .header--overlayed & {
    color: var(--color-white);
  }

  .header-search-box__field {
    width: 100%;

    .header--overlayed & {
      color: var(--color-white);

      &::placeholder {
        color: var(--color-teal-200);
      }
    }
  }

  & > button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 var(--space-300);

    .header--overlayed & {
      color: var(--color-white);
    }
  }
}
